import css from "@emotion/css";

export const centerCss = css`
  background: white;
  height: 100vh;
  display: flex;
  align-content: space-around;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  // background: url(./ssh.png) 0% 0% / 150px 150px #333
`;
export const leftCss = css`
  flex:1;
  display:flex;
  background:#655dc6;
  max-width:50%;
  justify-content:center;
  vertical-align:middle;
`;
export const rightCss = css`
  flex:1;
  display:flex;
  flex-direction:column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
`;

export const carouselCss = css`
  width:50vw;
  align-self:center;
`;