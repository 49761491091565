import React from "react";

import { Input, Form, Button, Alert } from "antd";
import { FormProps } from "antd/lib/form";
import { sha256 } from "js-sha256";

import { auth, logout } from "../../data/api/auth";
import { clearCache } from "../../data/api";

import {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import HalfRed from "../../components/background/HalfRed";
import { setCookie } from "../../utils/cookies";
import { logoCss } from "../../styles";
import { css } from "@emotion/core";

interface Props extends FormProps {
  history: any;
  location: any;
}

function Login(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState<string | undefined>(
    undefined,
  );

  React.useLayoutEffect(() => {
    const state = props.location.state;
    if (state && state.logout) {
      logout().catch((e: any) => console.error(e));
    }
  }, [props.location.state]);

  const onSubmit = React.useCallback(
    (values: any) => {
      setLoading(true);
      auth({
        username: values.username,
        password: sha256(values.password),
      })
        .then((r) => {
          setCookie("home", "dashboard");
          clearCache();
          setError(false);
          setTimeout(() => props.history.replace("/"), 1000);
        })
        .catch((err: any) => {
          let message = "An unexpected error was encountered.";
          if (err) {
            switch (err.status) {
              case 401:
                message = "You entered an incorrect e-mail or password.";
                break;
              case 404:
                message = "The server cannot be reached. If you cannot login after trying again, contact your system administrator.";
                break;
              default:
                message =
                  "An unexpected error was encountered. If you cannot login after trying again, contact your system administrator.";
            }
          }
          setLoading(false);
          setMessage(message);
          setError(true);
        });
    },
    [props],
  );
  return (
    <HalfRed>
      <img css={logoCss} src="huudle-logo-1024x1024.png" alt="Huudle Logo" />
      <Form onFinish={onSubmit}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please enter your e-mail address!",
            },
          ]}
        >
          <Input
            placeholder="Email"
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please enter your password!" },
          ]}
        >
          <Input.Password
            autoComplete="off"
            type="password"
            size="large"
            placeholder="Password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            size="large"
            loading={loading}
            style={{
              width: "100%",
              letterSpacing: 1,
              backgroundColor: "#655dc6",
              borderColor: "#655dc6",
              fontWeight: "bold",
            }}
            type="primary"
            icon={<LoginOutlined />}
          >
            Login
          </Button>
        </Form.Item>
        {message && (
          <Alert
            message={message}
            type={error ? "error" : "success"}
            showIcon={!error}
          />
        )}
        <Link to="/resetpassword" css={linkCss}>
          Forgot Password
        </Link>
      </Form>
    </HalfRed>
  );
}

export default React.memo(Login);


const linkCss = css`
  color: white;
  font-weight: bold;
  display:block;
  text-align:right;
`;