import React, { CSSProperties, PropsWithChildren } from "react";
import { carouselCss, centerCss, leftCss, rightCss, } from "./HalfRed.styles";
import { Carousel } from "antd";
import { css } from "@emotion/core";

interface Props {
  style?: CSSProperties;
}

export const HalfRed = (props: PropsWithChildren<Props>) => (
  <div css={centerCss} style={props.style}>
    <div css={leftCss}>
      <div css={carouselCss}>
        <Carousel autoplay>
          <div css={textCss} >Poll <br /> Huudle is an Agile Platform that lets you make polls urgently and go forward your work.</div>
          <div css={textCss} >Get Result <br /> Huudle is an Agile Platform that lets you make polls urgently and go forward your work.</div>
          <div css={textCss} >Decide<br /> Huudle is an Agile Platform that lets you make polls urgently and go forward your work.</div>
        </Carousel>
      </div>
    </div>
    <div css={rightCss}>
      {props.children}
    </div>
  </div>
);

export default HalfRed;


const textCss = css`
  font-size:24px;
  color:white;
  text-align:center;
  margin-bottom:90px;
`;