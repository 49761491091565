import React from "react";
import css from "@emotion/css";

import { Input, Form, Button, message } from "antd";

import { rowCss } from "../../components/publicPage/PublicPage.styles";
import { resetPassword } from "../../data/api/auth";

import { FormProps } from "antd/lib/form";

import { MailOutlined } from "@ant-design/icons";
import HalfRed from "../../components/background/HalfRed";
import Captcha from "../../components/form/inputs/Captcha";

interface IProps extends FormProps {
  age: number;
  name: string;
  history: any;
  location: any;
  match: any;
}

interface IState {
  loading: boolean;
  recaptchaResponseKey: string;
  recaptchaValid: boolean;
  submitable: boolean;
}

class ResetPassword extends React.Component<IProps, IState> {
  public state = {
    loading: false,
    recaptchaResponseKey: "",
    recaptchaValid: false,
    submitable: false,
  };

  public recaptchaRef: any;

  public verifyCallback = (responseKey: string) => {
    this.setState({
      recaptchaResponseKey: responseKey,
      recaptchaValid: true,
    });
  };

  public render() {
    return (
      <HalfRed>
        <img css={logoCss} src="huudle-logo-1024x1024.png" alt="logo"/>

        <div css={rowCss}>
          <div css={descCss}>
            You can send a reset link to your e-mail address from this page to set your new password.
          </div>
        </div>
        <Form onFinish={this.onSubmit}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Enter your email address",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email"
              size="large"
              prefix={<MailOutlined/>}
            />
          </Form.Item>
          <Form.Item>
            <Captcha
              captchaRef={this.recaptchaRef}
              onLoadCallback={this.onCaptchaLoaded}
              onExpiredCallback={() => this.recaptchaRef.current.reset()}
              verifyCallback={this.verifyCallback}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              loading={this.state.loading}
              style={{
                width: "100%",
                backgroundColor: "#e31d23",
                borderColor: "#c71b20",
                fontWeight: "bold",
              }}
              type="primary"
            >
              Send
            </Button>
          </Form.Item>
          <Button
            size="large"
            style={{
              width: "100%",
              fontWeight: "bold",
              color: "white"
            }}
            type="link"
            onClick={this.onBack}
          >
            Back
          </Button>
        </Form>
      </HalfRed>
    );
  }

  private onCaptchaLoaded = () => {
    this.setState({submitable: true});
  };
  private onBack = () => {
    window.history.back();
  };
  private onSubmit = (values: any) => {
    this.setState({loading: true});

    resetPassword({
        email: values.email,
      },
      this.state.recaptchaResponseKey)
      .then((r) => {
        message.success(
          "The password change link has been sent to your e-mail address.",
          5,
        );
        window.location.hash = "/login";
      })
      .catch((err: any) => {
        this.setState({loading: false});
        if (err.status === 404) {
          message.error("This user is not registered on the system.");
        } else {
          message.error("Something went wrong.");
        }
      });
  };
}

export const descCss = css`
  text-align: left;
  margin-left: -14px;
  color: white;
  font-weight:bold
`;
const logoCss = css`
  width: 100px;
  margin-bottom:20px;
`;
export default ResetPassword;
