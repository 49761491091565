import React from "react";
import css from "@emotion/css";

import { Input, Form, Button, message } from "antd";
import { sha256 } from "js-sha256";

import { setPassword } from "../../data/api/auth";
import { rowCss } from "../../components/publicPage/PublicPage.styles";
import { FormProps } from "antd/lib/form";

import { LockOutlined } from "@ant-design/icons";
import HalfRed from "../../components/background/HalfRed";

interface IProps extends FormProps {
  age: number;
  name: string;
  history: any;
  location: any;
  match: any;
}

interface IState {
  loading: boolean;
}

class SetPassword extends React.Component<IProps, IState> {
  public state = {loading: false};

  public render() {
    return (
      <HalfRed>
        <img css={logoCss} src="huudle-logo-1024x1024.png" alt="logo"/>

        <div css={rowCss}>
          <div css={descCss}>
            You can set your new password on this page. The password you enter and the password repeat must be the same.
          </div>
        </div>
        <Form onFinish={this.onSubmit}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Enter your new password",
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Password"
              size="large"
              prefix={<LockOutlined/>}
            />
          </Form.Item>
          <Form.Item
            name="passwordAgain"
            rules={[
              {
                required: true,
                message: "Please enter the password again.",
              },
              ({getFieldValue}) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The passwords entered do not match.",
                  );
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              size="large"
              placeholder="Password (Repeat)"
              prefix={<LockOutlined/>}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              loading={this.state.loading}
              style={{width: "100%"}}
              type="primary"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </HalfRed>
    );
  }

  // TODO: need to change validating and update
  private onSubmit = (values: any) => {
    this.setState({loading: true});

    setPassword(this.props.match.params.token, {
      password: sha256(values.password),
    })
      .then((r) => {
        message.success("Your password has been successfully changed.", 5);
        window.location.hash = "/login";
      })
      .catch((fail: any) => {
        if (fail.cause.status === 401) {
          message.error(
            "You should not be on this page, you were sent with a bad email.",
            5,
          );
        }
        this.setState({loading: false});
      });
  };
}

export const descCss = css`
  text-align: left;
  margin-left: -14px;
  color: white;
`;
const logoCss = css`
  width: 100px;
  margin-bottom:20px;
`;
export default SetPassword;
