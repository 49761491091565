import css from "@emotion/css";

export const centerCss = css`
  text-align: center;
`;


export const logoCss = css`
  width: 100px;
  margin-bottom:20px;
`;
export const titleCss = css`
  margin-bottom:30px;
  font-size:29px;
  text-align:center;
  font-weight:bolder;
  color:white;
  line-height:1.3;
`;