import { load } from "./index";
import { User } from "../models/User";

export interface IAuthReq {
  username: string;
  password: string;
}

export const auth = (data: IAuthReq): Promise<User> =>
load("auth", "POST", false, data, undefined, { "X-Username": data.username, });

export const logout = (): Promise<any> =>
  load("auth/logout", "GET", false, undefined);

export const setPassword = (token: string, data: any): Promise<any> =>
  load(`auth/setpassword/${token}`, "POST", false, data);
export const resetPassword = (
  data: any,
  responseKey: string,
): Promise<any> =>
  load(`auth/resetpassword/${responseKey}`, "POST", false, data);

export default auth;
