import React from "react";
import isEqual from "react-fast-compare";
import AsyncRoute from "../../components/AsyncRoute";

import { Switch } from "react-router-dom";
import { findInTree } from "../../utils/collections/tree";
import { Menu } from "../../data";

let FILTERED_ROUTES: any[];

export function calculateRoutes(menu: Menu[]) {
  FILTERED_ROUTES = routes()
    .filter((i: Route) => findInTree(menu, i.path, "Path", "Children"))
    .map((i: Route, idx: number) => (
      <AsyncRoute exact key={i.path} path={i.path} imp={i.imp} />
    ));
  return Promise.resolve(FILTERED_ROUTES);
}

export const MainRouter = React.memo(
  (props: any) => {
    return (
      <Switch>
        {FILTERED_ROUTES}
        {commonRoutes()}
      </Switch>
    );
  },
  (p: any, n: any) => isEqual(p, n),
);

interface Route {
  path: string;
  imp: any;
}

function routes() {
  const routeArr: Route[] = [
    { path: "/", imp: () => import("../dashboard/DashboardRenderer") },
    { path: "/users", imp: () => import("../users/Users") },
    { path: "/roles", imp: () => import("../roles/Roles") },
    { path: "/menus", imp: () => import("../menus/Menus") },
    { path: "/system/api", imp: () => import("../system/Api") },
    { path: "/parameters", imp: () => import("../parameters/Parameters") },
    {
      path: "/emails/emailTemplates",
      imp: () => import("../emails/emailTemplates/EmailTemplates"),
    },
    {
      path: "/emails/outgoing",
      imp: () => import("../emails/EmailOutgoing"),
    },
    {
      path: "/activityLogs",
      imp: () => import("../activityLog/ActivityLog"),
    },
    {
      path: "/notifications",
      imp: () => import("../notification/Notification"),
    },
    {
      path: "/workspaces",
      imp: () => import("../workspaces/Workspaces"),
    }
  ];

  return routeArr;
}

function commonRoutes() {
  return [
    <AsyncRoute
      exact
      key="/"
      path="/"
      imp={() => {
        return import("../dashboard/DashboardRenderer");
      }}
    />,
    <AsyncRoute
      exact
      key="/profile"
      path="/profile"
      imp={() => import("../profile/Profile")}
    />,
    <AsyncRoute
      exact
      key="/forbidden"
      path="/forbidden"
      imp={() => import("../error/Forbidden")}
    />,
    <AsyncRoute key="notfound" imp={() => import("../error/NotFound")} />,
  ];
}
