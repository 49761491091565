import React from "react";
import { Layout } from "antd";
import { HeaderButton } from "./header/HeaderButton";
import SideMenu from "./SideMenu";
import { Menu as MenuModel } from "../../data/models/Menu";
import { tree as menuTree } from "../../data/api/menus";
import { NotificationsButton } from "./header/notifications/NotificationsButton";
import { haveToken } from "../../utils/cookies";
import { MenuTrigger } from "./header/MenuTrigger";
import { Breadcrumb } from "./header/Breadcrumb";
import { LogoutButton } from "./header/LogoutButton";
import { Header } from "./header/Header";
import { Content } from "./Content";

import { QuestionOutlined } from "@ant-design/icons";

import { MainRouter, calculateRoutes } from "./Main.router";
import { isMobile } from "../../utils/styles";
import { User } from "../../data";
import { ProfileButton } from "./header/ProfileButton";
import { css } from "@emotion/core";
import { profile } from "../../data/api/users";
const { Sider } = Layout;

// get props from navigator
function Main(props: any) {
  const [collapsed, setCollapsed] = React.useState(false);
  const [menu, setMenu] = React.useState<MenuModel[]>([]);
  const [user, setUser] = React.useState<User>();

  const toggle = React.useCallback(() => {
    localStorage.setItem("main.menu.collapsed", (!collapsed).toString());
    setCollapsed(!collapsed);
  }, [collapsed]);

  const onMenuSelect = React.useCallback(
    (item: any) => {
      window.scrollTo(0, 0);
      props.history.push(item.key);
    },
    [props.history],
  );

  React.useLayoutEffect(() => {
    if (!haveToken()) {
      window.location.hash = "/login";
    }
  }, []);

  React.useEffect(() => {
    const collapsed =
      isMobile() || localStorage.getItem("main.menu.collapsed") === "true";
    setCollapsed(collapsed);
    if (haveToken()) {
      menuTree(undefined, true)
        .then((resp) => {
          if (resp.Children) {
            calculateRoutes(resp.Children).then(() => {
              if (resp.Children) {
                setMenu(resp.Children);
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
      profile().then((user: User) => {
        setUser(user);
      });
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="dark">
        <div>
          <a href="/#/">
            {collapsed ? (
              <img css={logoSmallCss} src="./huudle-logo-1024x1024.png" alt="e-logo" />
            ) : (
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <img css={logoBigCss} src="./huudle-logo-1024x1024.png" alt="e-logo" />
              </div>
            )}
          </a>
        </div>

        <SideMenu
          data={menu}
          onSelect={onMenuSelect}
          selectedKeys={[props.location.pathname]}
          path={props.location.pathname}
          collapsed={collapsed}
        />
      </Sider>
      <Layout css={bg}>
        <Header>
          <MenuTrigger collapsed={collapsed} onClick={toggle} />
          <Breadcrumb menu={menu} path={props.location.pathname} />
          <LogoutButton />
          <HeaderButton
            tooltip="Help"
            type="default"
            icon={<QuestionOutlined />}
            to="/faqs"
          />
          <NotificationsButton />
          <ProfileButton name={`${user?.FirstName} ${user?.LastName}`} />
        </Header>

        <Content>
          <MainRouter path={menu.length && props.location.pathname} />
        </Content>
      </Layout>
    </Layout>
  );
}

const bg = css`
  background-image: url(./ssh.png);
  background-blend-mode: overlay;
  background-size: 50px 50px;
`;

const logoSmallCss = css`
  height: 25px;
  margin: 10px;
  margin-left: 10px;
`;
const logoBigCss = css`
  height: 45px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 8px;
`;

export default Main;
